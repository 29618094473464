import React, { useState } from "react";

import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { Search, SearchSuggestion } from "@/components/search";
import { Typography } from "@/components/typography/typography";
import type { PartialJob } from "@/context/jobs";
import { useJobsContext } from "@/context/jobs";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import { getJobUrl } from "@/utils/greenhouse";

import type { OptionComponentProps } from "./types";

const OptionComponent = ({ job }: OptionComponentProps) => {
	const { title, location } = job;
	return (
		<>
			<Typography tight weight={FONT_WEIGHT.light} variant={TypographyVariant.bodyLG}>
				{title}
			</Typography>
			<Typography light tight weight={FONT_WEIGHT.light} variant={TypographyVariant.bodySM}>
				{location?.name?.split(",")?.[0]}
			</Typography>
		</>
	);
};

export const JobSearchAutocomplete = () => {
	const { locale, push } = useRouter();
	const { t } = useTranslation(["common", "jobs"]);
	const [noSuggestionsFound, setNoSuggestionsFound] = useState(false);
	const [jobs, setJobs] = useState<PartialJob[]>([]);
	const { error, searchJobsByQuery, loading } = useJobsContext();

	const fetchSuggestions = (query: string) => {
		// Reset list if query empty or less than 2 chars
		if (!query || query.length <= 1) {
			setJobs([]);
			return;
		}

		setNoSuggestionsFound(false);

		const results = searchJobsByQuery(query);
		if (results.length === 0) {
			setNoSuggestionsFound(true);
		}
		setJobs(results);
	};

	const handleConfirm = ({ id, title }: PartialJob) => {
		const jobPostUrl = getJobUrl({ locale, id, title });

		void push(jobPostUrl);
	};

	return (
		<Search
			hideButton
			limitHeight
			error={Boolean(error)}
			errorText={t("common:generic-error")}
			fetchSuggestions={fetchSuggestions}
			name="jobs-autocomplete"
			noSuggestionsFound={noSuggestionsFound}
			placeholder={t("jobs:search.placeholder")}
			handleConfirm={() => {
				if (jobs[0]) {
					handleConfirm(jobs[0]);
				}
			}}
		>
			{!loading &&
				jobs.map(job => (
					<SearchSuggestion
						id={job.id.toString()}
						key={job.id}
						role="option"
						tabIndex={-1}
						type="button"
						onClick={() => {
							handleConfirm(job);
						}}
					>
						<OptionComponent job={job} />
					</SearchSuggestion>
				))}
		</Search>
	);
};
