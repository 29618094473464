import React from "react";

import { useTranslation } from "next-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { Column, Row } from "@/components/grid";
import { Typography } from "@/components/typography/typography";
import {
	StyledBoxWrapper,
	StyledContent,
	StyledIcon,
	StyledInnerBox,
	StyledLink,
} from "@/components/valuation-options/components";
import { CALENDLY_ON_SITE_VALUATION_URL, CALENDLY_VIDEO_VALUATION_URL } from "@/constants/urls";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";
import { IconSize } from "@/design-system/atoms/icons";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import { urlWithQuery } from "@/utils/urls";

export const ValuationOptions: React.FC = () => {
	const { t } = useTranslation(["valuation"]);
	const { locale } = useRouter();
	const valuationLink = `/${dirs[Dirs.valuation].dir[locale] as string}/`;

	return (
		<Row>
			<Column s={0} l={1} />
			<StyledBoxWrapper l={6}>
				<Row>
					<Column l={2}>
						<Link passHref href={urlWithQuery(valuationLink)} locale={locale}>
							<StyledLink>
								<StyledInnerBox>
									<StyledIcon icon="click" size={IconSize.l} />
									<StyledContent>
										<Typography variant={TypographyVariant.bodySM}>
											{t("valuation:online-valuation")}
										</Typography>
										<Typography
											variant={TypographyVariant.bodySM}
											weight={FONT_WEIGHT.light}
										>
											{t("valuation:online-valuation-time")}
										</Typography>
									</StyledContent>
								</StyledInnerBox>
							</StyledLink>
						</Link>
					</Column>
					<Column l={2}>
						<StyledLink
							target="_blank"
							href={urlWithQuery(CALENDLY_VIDEO_VALUATION_URL)}
						>
							<StyledInnerBox>
								<StyledIcon icon="video" size={IconSize.l} />
								<StyledContent>
									<Typography variant={TypographyVariant.bodySM}>
										{t("valuation:video-valuation")}
									</Typography>
									<Typography
										weight={FONT_WEIGHT.light}
										variant={TypographyVariant.bodySM}
									>
										{t("valuation:video-valuation-time")}
									</Typography>
								</StyledContent>
							</StyledInnerBox>
						</StyledLink>
					</Column>
					<Column l={2}>
						<StyledLink
							target="_blank"
							href={urlWithQuery(CALENDLY_ON_SITE_VALUATION_URL)}
						>
							<StyledInnerBox>
								<StyledIcon icon="handshake" size={IconSize.l} />
								<StyledContent>
									<Typography variant={TypographyVariant.bodySM}>
										{t("valuation:on-site-valuation")}
									</Typography>
									<Typography
										weight={FONT_WEIGHT.light}
										variant={TypographyVariant.bodySM}
									>
										{t("valuation:on-site-valuation-time")}
									</Typography>
								</StyledContent>
							</StyledInnerBox>
						</StyledLink>
					</Column>
				</Row>
			</StyledBoxWrapper>
		</Row>
	);
};
