import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Typography } from "@/components/typography/typography";

export const ResultWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;
`;

export const IconWrapper = styled.div`
	padding-top: 1px;
`;

export const LocationType = styled(Typography)`
	${({ theme }) => css`
		color: ${theme.palette.freeze[500]};
	`};
`;
