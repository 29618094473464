import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";

import { Hidden } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Attribute, AttributeSizeOptions } from "@/design-system/atoms/attribute";
import { UnitType } from "@/design-system/atoms/unit/types";
import type { PropsWithTheme } from "@/theme";

const WidgetBox = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: stretch;
	width: 100%;
	${({ theme: { mq, palette } }) => css`
		background: ${palette.freeze[0]};
		color: ${palette.freeze[1000]};
		@media ${mq.l} {
			z-index: 1;
			width: 75%;
			position: absolute;
			bottom: -13%;
			left: 2%;
			box-shadow: 0 30px 27px rgba(0, 0, 0, 0.09);
		}
	`};
`;

const StyledResultWidget = styled.div<PropsWithTheme>`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding-top: var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			flex-direction: row;
			justify-content: start;
			padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-m);
		}
	`};
`;

const StyledAttributes = styled.div<PropsWithTheme>`
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: space-around;
	width: 100%;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			gap: 2rem;
			width: 70%;
		}
	`};
`;

export interface ResultWidgetProps {
	livingArea: string | number;
	landArea?: string | number;
	buildingYear: string | number;
}

export const ResultWidget: React.FC<ResultWidgetProps> = ({
	livingArea,
	landArea,
	buildingYear,
}) => {
	const { t } = useTranslation(["common", "valuation", "property"]);

	return (
		<WidgetBox>
			<StyledResultWidget>
				<StyledAttributes>
					{livingArea && (
						<Attribute
							icon="livingSpace"
							size={AttributeSizeOptions.largeResponsive}
							title={t("property:living-space")}
							unit={UnitType.m}
							value={livingArea}
						/>
					)}
					{landArea && (
						<Attribute
							icon="landArea"
							size={AttributeSizeOptions.largeResponsive}
							title={t("property:property-area")}
							unit={UnitType.m}
							value={landArea}
						/>
					)}
					{buildingYear && (
						<Attribute
							icon="buildingYear"
							size={AttributeSizeOptions.largeResponsive}
							title={t("valuation:property-details:year")}
							value={buildingYear}
						/>
					)}
				</StyledAttributes>
				<Hidden s m>
					<Spacer spacing="s" />
				</Hidden>
			</StyledResultWidget>
		</WidgetBox>
	);
};
