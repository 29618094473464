import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Column } from "@/components/grid";
import { Icon } from "@/design-system/atoms/icons";
import type { PropsWithTheme } from "@/theme";

export const StyledBoxWrapper = styled(Column)<PropsWithTheme>`
	z-index: 1;
	margin-bottom: calc(var(--spacing-xxxl) * -1);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			margin-bottom: calc(var(--spacing-xxl) * -1);
		}
	`};
`;

export const StyledIcon = styled(Icon)`
	margin-right: var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			margin-right: 0;
			margin-bottom: var(--spacing-xxs);
		}
	`};
`;

export const StyledLink = styled.a<PropsWithTheme<HTMLAnchorElement>>`
	display: block;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			position: relative;
			padding-bottom: calc(100%);
		}
	`};
`;

export const StyledInnerBox = styled.div<PropsWithTheme>`
	display: flex;
	align-items: center;
	height: var(--spacing-xxl);
	margin-bottom: var(--spacing-xxs);
	padding: var(--spacing-xs);
	border-radius: 10px;
	box-shadow: 0 30px 27px rgba(0, 0, 0, 0.09);
	${({ theme: { mq, palette } }) => css`
		color: ${palette.freeze[500]};
		background-color: ${palette.freeze[0]};
		@media ${mq.l} {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 0;
			height: 100%;
		}
	`};
`;

export const StyledContent = styled.div<PropsWithTheme>`
	display: flex;
	${({ theme: { mq } }) => css`
		align-items: flex-start;
		flex-direction: column;
		@media ${mq.l} {
			align-items: center;
		}
	`};
`;
