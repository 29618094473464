import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Portal from "@material-ui/core/Portal";
import CloseIcon from "@material-ui/icons/Close";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import type { SpringConfig } from "@react-spring/web";
import { animated, config, useSpringRef, useChain, useSpring } from "@react-spring/web";
import { useTranslation } from "next-i18next";

import Link from "next/link";

import { JobSearchAutocomplete } from "@/components/autocomplete/implementations/job-search";
import { MapLocationAutocomplete } from "@/components/autocomplete/implementations/map-location";
import { JobApplicationFormWithSnackbar } from "@/components/forms/job-application-form/with-snackbar";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { RelativeColumn } from "@/components/grid/extensions";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { ValuationOptions } from "@/components/valuation-options";
import { ResultWidget } from "@/components/valuation-result/result-widget";
import type { ResultWidgetProps } from "@/components/valuation-result/result-widget";
import { Valuation, ValuationArea } from "@/components/visual/components";
import { AbsoluteIconButton, ButtonLink } from "@/design-system/atoms/button";
import { Icon, IconSize } from "@/design-system/atoms/icons";
import { useViewport } from "@/design-system/organisms/slideshow-x/rewrites";
import { useVideoContext } from "@/hooks/video";
import type { PropsWithTheme } from "@/theme";
import { TypographyVariant } from "@/theme";
import type {
	JobLocation,
	JobLocationCollection,
	KeyVisual,
	MediaPlayer,
	TextCollection,
} from "@/types/contentful-api";

export enum WidgetType {
	videoModal = "video-modal",
	valuationEntry = "valuation-tool",
	valuationOptions = "valuation-options",
	applyAsAgent = "apply-as-agent",
	valuationResult = "valuation-result",
	ctaToExternalResource = "cta-to-external-resource",
	mapLocationSearch = "map-location-search",
	jobSearch = "job-search",
}

const StyledModal = styled(animated.div)<PropsWithTheme>`
	display: flex;
	position: fixed;
	z-index: 300;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-content: center;
	align-items: center;
	justify-content: center;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
	`};
`;

const ModalButton = styled.button<PropsWithTheme<HTMLButtonElement>>`
	display: inline-flex;
	align-content: center;
	align-items: center;
	border: 0;
	background: none;
	color: inherit;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
`;

const ModalButtonIcon = styled.span`
	display: inline-flex;
	margin-right: var(--spacing-xxs);
`;

const Clipped = styled(animated.div)`
	will-change: clip-path;
`;

const CloseButton = styled(AbsoluteIconButton)<PropsWithTheme<HTMLButtonElement>>`
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			top: 0;
			right: 0;
			transform: translateY(calc(-100% - var(--spacing-xl)));
		}
	`};
`;

const VideoText = styled.div`
	display: flex;
	align-content: center;
	align-items: center;
	padding-left: var(--spacing-xs);
`;

const MuteButton = styled.button`
	border: 0;
	background: none;
	color: inherit;
	font-size: 1em;
`;

const getClipPathFromTime = (time: number) =>
	`polygon(${50 * time}% 0%, ${100 - 50 * time}% 0%, ${100 - 50 * time}% 100%, ${
		50 * time
	}% 100%)`;

const ButtonCTA = styled(ButtonLink)`
	margin-left: 12px;
	height: 45px;
	font-weight: 500;
`;

interface ViewModalProps {
	onClose: () => void;
	onRestOpen?: () => void;
	onRestClose?: () => void;
	open?: boolean;
	springConfig?: SpringConfig;
}

const VideoModal: React.FC<ViewModalProps> = ({
	children,
	onClose,
	onRestClose,
	onRestOpen,
	open,
	springConfig,
}) => {
	const { play } = useVideoContext();
	const { t } = useTranslation("common");

	const xRef = useSpringRef();
	const yRef = useSpringRef();
	const { x } = useSpring({
		ref: xRef,
		reverse: !open,
		config: springConfig,
		from: {
			x: 1,
		},
		to: {
			x: 0,
		},
		onRest() {
			if (onRestOpen) {
				onRestOpen();
			}
		},
	});
	const { y } = useSpring({
		ref: yRef,
		reverse: !open,
		config: springConfig,
		from: {
			y: 1,
		},
		to: {
			y: 0,
		},
		onRest(state) {
			if (state.value.y === 1) {
				play();
				if (onRestClose) {
					onRestClose();
				}
			}
		},
	});

	useChain(open ? [xRef, yRef] : [yRef, xRef], [1, 0]);

	const clippedStyle: React.CSSProperties = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		clipPath: x.to((time: number) => getClipPathFromTime(time)) as any,
	};

	const modalStyle: React.CSSProperties = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		transform: y.to((time: number) => `translate3d(0,${time * -100}%, 0)`) as any,
	};

	return (
		<Portal>
			<StyledModal style={modalStyle}>
				<Grid overflow>
					<Row>
						<RelativeColumn s={0} l={12}>
							<CloseButton
								type="button"
								aria-label={t("common:close")}
								onClick={onClose}
							>
								<CloseIcon />
							</CloseButton>
						</RelativeColumn>
					</Row>
					<Row>
						<Column s={0} l={1} />
						<Column l={10}>
							<Clipped style={clippedStyle}>{children}</Clipped>
						</Column>
					</Row>
				</Grid>
				<Hidden s m>
					<CloseButton type="button" aria-label={t("common:close")} onClick={onClose}>
						<CloseIcon />
					</CloseButton>
				</Hidden>
			</StyledModal>
		</Portal>
	);
};

const StyledVideo = styled.video`
	width: 100%;
	height: auto;
`;
export interface InlineVideoProps extends React.HTMLProps<HTMLVideoElement> {
	playing?: boolean;
	stopped?: boolean;
}
const InlineVideo: React.FC<InlineVideoProps> = ({ src, playing, stopped, muted }) => {
	const ref = React.useRef<HTMLVideoElement>();

	React.useEffect(() => {
		if (ref.current) {
			if (playing) {
				try {
					void ref.current.play();
				} catch {
					// Usually play interrupted by user setting or route change
				}
			} else {
				ref.current.pause();
			}
		}
	}, [playing, ref]);

	React.useEffect(() => {
		if (ref.current && stopped) {
			ref.current.currentTime = 0;
		}
	}, [stopped, ref]);

	React.useEffect(() => {
		if (ref.current) {
			ref.current.muted = muted;
		}
	}, [muted, ref]);

	React.useEffect(() => {
		if (ref.current && !stopped) {
			try {
				void ref.current.play();
			} catch {
				// Usually play interrupted by user setting or route change
			}
		}
	}, [src, stopped]);

	return <StyledVideo ref={ref} playsInline loop src={src} muted={muted} />;
};

/**
 * @ToDo: Use new MediaPlayer instead of KeyVisual (layout4.tsx) on pages/subpages
 * Currently old method only used on /verkaufen
 * Can be done when query limits have been resolved
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VideoWidget: React.FC<{ data: any }> = ({ data }) => {
	const { widgetTitle, featuredVideo, videoHd, videoSd } = data;
	const { t } = useTranslation(["video"]);
	const { l } = useViewport();
	const [open, setOpen] = React.useState(false);
	const [playing, setPlaying] = React.useState(false);
	const [muted, setMuted] = React.useState(true);
	const [stopped, setStopped] = React.useState(false);
	const { pause } = useVideoContext();

	const description = featuredVideo ? featuredVideo.description : videoHd.description;

	const openVideoModal = React.useCallback(() => {
		setOpen(true);
		pause();
	}, [pause]);

	const getSrc = React.useCallback(
		() => (featuredVideo ? featuredVideo.url : l ? videoHd.url : videoSd.url),
		[l, featuredVideo, videoHd, videoSd]
	);
	return (
		<>
			<ModalButton type="button" onClick={openVideoModal}>
				<ModalButtonIcon>
					<Icon icon="video" size={IconSize.l} />
				</ModalButtonIcon>
				<Typography component="h4" variant={TypographyVariant.headlineSansXXS}>
					{widgetTitle}
				</Typography>
			</ModalButton>
			<VideoModal
				open={open}
				springConfig={{ ...config.stiff, clamp: true }}
				onClose={() => {
					setOpen(false);
					setPlaying(false);
				}}
				onRestOpen={() => {
					setStopped(false);
					setPlaying(true);
				}}
				onRestClose={() => {
					setStopped(true);
				}}
			>
				<InlineVideo src={getSrc()} playing={playing} stopped={stopped} muted={muted} />
				<Spacer spacing="xxs" />
				<Row>
					<Column>
						<VideoText>
							{description}
							<MuteButton
								aria-label={t(muted ? "unmute" : "mute")}
								onClick={() => {
									setMuted(s => !s);
								}}
							>
								{muted ? <VolumeOffIcon /> : <VolumeMuteIcon />}
							</MuteButton>
						</VideoText>
					</Column>
				</Row>
			</VideoModal>
		</>
	);
};

export const Widget: React.FC<{
	type: WidgetType;
	data?: Partial<KeyVisual | MediaPlayer | ResultWidgetProps | JobLocationCollection>;
	textCollection?: TextCollection;
}> = ({ type, data, textCollection }) => {
	switch (type) {
		case WidgetType.videoModal:
			if (data && ("videoHd" in data || "featuredVideo" in data)) {
				return <VideoWidget data={data as KeyVisual} />;
			}
			break;
		case WidgetType.valuationEntry:
			if (data && "widgetTitle" in data) {
				return (
					<Column l={9}>
						<ValuationArea>
							<Valuation title={data.widgetTitle} />
						</ValuationArea>
					</Column>
				);
			}
			break;
		case WidgetType.valuationResult:
			if (data && "livingArea" in data) {
				return <ResultWidget {...(data as ResultWidgetProps)} />;
			}
			break;
		case WidgetType.applyAsAgent:
			if (data && "jobLocationsCollection" in data) {
				return (
					<JobApplicationFormWithSnackbar
						isAgentApplicationForm
						jobLocations={data.jobLocationsCollection.items as JobLocation[]}
						textCollection={textCollection}
					/>
				);
			}
			break;
		case WidgetType.valuationOptions:
			return (
				<Column l={7}>
					<ValuationOptions />
				</Column>
			);
		case WidgetType.jobSearch:
			return <JobSearchAutocomplete />;
		case WidgetType.mapLocationSearch:
			return <MapLocationAutocomplete />;
		case WidgetType.ctaToExternalResource:
			if (data && "externalCta" in data) {
				const { externalUrl, label } = data.externalCta;
				return (
					<Link href={externalUrl} passHref>
						<ButtonCTA color="secondary" target="_blank" rel="noopener noreferrer">
							{label}
						</ButtonCTA>
					</Link>
				);
			}
		default:
			return null;
	}
};
