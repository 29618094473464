import React from "react";

export const VideoContext = React.createContext({
	playing: false,
	stop: () => {
		/**/
	},
	play: () => {
		/**/
	},
	pause: () => {
		/**/
	},
	ref: { current: null },
});
